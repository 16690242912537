import React, { useEffect, useMemo, useState } from "react";
import {
  Calendar,
  dateFnsLocalizer,
  momentLocalizer,
  Views,
} from "react-big-calendar";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toastify } from "../../components/Toast";
import { ToastContainer } from "react-toastify";

import {
  createActivity,
  deleteActivity,
  getselectedUserActivities,
  getSelectedActivityDataOfUser,
  getUserGoals,
  updateActivity,
} from "../../redux/actions/authAction";
import moment from "moment";
import "moment-timezone";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import ImageCompress from "quill-image-compress";
const ManageActivitiesSelected = () => {
  const [allEvents, setAllEvents] = useState([]);
  const [titleState, setTitleState] = useState("");
  const [descriptionState, setDescriptionState] = useState("");
  const [goalState, setGoalState] = useState("");
  const [show, setShow] = useState(false);
  const [arrayOfGoals, setArrayOfGoals] = useState([]);
  const [startDateState, setStartDateState] = useState(new Date());
  const [loaderForCreateActivity, setLoaderForCreateActivity] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState();
  // edit
  const [editActivityModal, setEditActivityModal] = useState(false);
  const [editTitleState, setEditTitleState] = useState("");
  const [editDescriptionState, setEditDescriptionState] = useState("");
  const [editGoalState, setEditGoalState] = useState("");
  const [editGoalFirstState, setEditGoalFirstState] = useState("");
  const [editStartDateState, setEditStartDateState] = useState("");
  const [editEndDateState, setEditEndDateState] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();
  const [clientInfo] = useState(location?.state?.e);
  const { allActivities, selectedUserGoals, selectedActivityDataOfUser } =
    useSelector((state) => state?.getManageActivityData);
  useEffect(() => {
    setEditTitleState(selectedActivityDataOfUser?.data?.activity?.title);
    setEditDescriptionState(
      selectedActivityDataOfUser?.data?.activity?.description
    );
    setEditGoalFirstState(
      selectedActivityDataOfUser?.data?.activity?.goalId?.title
    );
  }, [selectedActivityDataOfUser]);

  useEffect(() => {
    if (clientInfo) {
      dispatch(
        getselectedUserActivities(clientInfo?._id, callbackForUserActivities)
      );
      dispatch(getUserGoals(clientInfo?._id));
    }
  }, []);

  const callbackForUserActivities = (response) => {
    const eventsData = response?.data?.map((item) => {
      item.start = new Date(item?.startDate);
      item.end = new Date(item?.endDate);

      return item;
    });

    setAllEvents(eventsData);
  };
  moment.tz.setDefault("Europe/London");
  const localizer = momentLocalizer(moment);
  const { views } = useMemo(
    () => ({
      views: [Views.MONTH],
    }),
    []
  );

  const onAddEventBtn = () => {
    if (selectedUserGoals?.data?.length) {
      handleShow();
    } else {
      toastify("error", "User don't have any selected goals");
    }

    setArrayOfGoals(selectedUserGoals);
  };
  const handleClose = () => {
    setShow(false);
    setDescriptionState("");
  };
  const onSaveEventData = () => {
    setLoaderForCreateActivity(true);
    const startDate = new Date(startDateState);
    const isoStartDate = new Date(
      startDate.getTime() - startDate.getTimezoneOffset() * 60000
    ).toISOString();

    const userData = {
      userId: clientInfo?._id,
      endDate: isoStartDate,
      startDate: isoStartDate,
      title: titleState,
      description: descriptionState,
      goalId: goalState,
    };
    if (userData.title !== "") {
      dispatch(
        createActivity(
          userData,
          setLoaderForCreateActivity,
          callbackForNewActivity,
          userData?.userId
        )
      );
    } else {
      toastify("error", "Please add title,its compulsory");
    }
  };
  const callbackForNewActivity = (res, userId) => {
    setShow(false);
    dispatch(getselectedUserActivities(userId, callbackForUserActivities));
    setTitleState("");
    setDescriptionState("");
    setGoalState("");
    setStartDateState(new Date());
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleSelectEvent = (event, e) => {
    dispatch(
      getSelectedActivityDataOfUser(event?._id, callBackForSelectedUserActivity)
    );
    setSelectedActivityId(event?._id);
    setArrayOfGoals(selectedUserGoals);
    setEditStartDateState(event?.start);
    setEditEndDateState(event?.end);
  };
  const callBackForSelectedUserActivity = (res) => {
    if (res) {
      setEditActivityModal(true);
    }
  };
  const onEditActivityModalClose = () => {
    setEditActivityModal(false);
    setEditGoalState("");
    setEditDescriptionState("");
  };
  const onUpdateActivityHandler = () => {
    const updateActivityObj = {
      title: editTitleState,
      goalId:
        editGoalState === ""
          ? selectedActivityDataOfUser?.data?.activity?.goalId?._id
          : editGoalState,
      userId: clientInfo?._id,
      description: editDescriptionState,
      startDate: editStartDateState,
      endDate: editEndDateState,
    };
    dispatch(updateActivity(selectedActivityId, updateActivityObj));
  };
  const onDeleteActivityHandler = () => {
    dispatch(deleteActivity(selectedActivityId));
  };
  //React Quill
  Quill.register("modules/ImageResize", ImageResize);
  Quill.register("modules/imageCompress", ImageCompress);
  const editorModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],

      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
    ],
    ImageResize: {
      parchment: Quill.import("parchment"),
      handleStyles: {
        displaySize: true,
        backgroundColor: "black",
        border: "none",
        color: "white",
      },
      modules: ["Resize", "DisplaySize"],
    },
    imageCompress: {
      quality: 0.7, // default
      maxWidth: 700,
      maxHeight: 700,
      imageType: ["image/jpeg", "image/png", "image/jpg"], // default
      debug: true, // default
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const editorFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  return (
    <>
      <>
        {" "}
        <ToastContainer />
      </>
      {/* ADD ACTIVITY MODAL */}
      <>
        <Modal
          backdrop="static"
          keyboard={false}
          data-dismiss="modal"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header
            className="modal-header-workout"
            closeButton={!loaderForCreateActivity}
          >
            <Modal.Title>Add an activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="write a title for your activity"
                    onChange={(e) => setTitleState(e.target.value)}
                    value={titleState}
                  />
                </Form.Group>{" "}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>

                  <ReactQuill
                    theme="snow"
                    value={descriptionState}
                    onChange={setDescriptionState}
                    modules={editorModules}
                    formats={editorFormats}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Select goals</Form.Label>

                  <>
                    <form className="goalSelectorFieldForm">
                      <select
                        className="goalSelectorField"
                        onChange={(event) => setGoalState(event.target.value)}
                        value={goalState}
                      >
                        <option hidden className="first-options">
                          Select your goals
                        </option>
                        {arrayOfGoals?.data?.map((ele) => {
                          return (
                            <>
                              <option value={ele?._id}>{ele?.title}</option>
                            </>
                          );
                        })}
                      </select>
                    </form>
                  </>
                </Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <DatePicker
                    onChange={setStartDateState}
                    value={startDateState}
                  />
                </Form.Group>
                {/* <Form.Label>End date</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                 
                  <DatePicker onChange={setEndDateState} value={endDateState} />
                </Form.Group> */}
              </Form>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose}
              disabled={loaderForCreateActivity}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onSaveEventData}
              disabled={loaderForCreateActivity}
            >
              {loaderForCreateActivity ? (
                <Spinner
                  className=""
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      {/* EDIT ACTIVITY */}
      <>
        <Modal
          backdrop="static"
          keyboard={false}
          data-dismiss="modal"
          show={editActivityModal}
          onHide={onEditActivityModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>Edit an activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="write a title for your activity"
                    onChange={(e) => setEditTitleState(e.target.value)}
                    value={editTitleState}
                  />
                </Form.Group>{" "}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <ReactQuill
                    theme="snow"
                    value={editDescriptionState}
                    onChange={setEditDescriptionState}
                    modules={editorModules}
                    formats={editorFormats}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <div className="goal-for-plan mb-3">
                    <Form.Label>Goal</Form.Label>
                    <Form.Select
                      onChange={(e) => setEditGoalState(e.target.value)}
                      value={editGoalState}
                    >
                      <option hidden>{editGoalFirstState}</option>
                      {selectedUserGoals?.data?.map((goal, index) => {
                        return <option value={goal?._id}>{goal?.title}</option>;
                      })}
                    </Form.Select>
                  </div>
                </Form.Group>
              </Form>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onEditActivityModalClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={onDeleteActivityHandler}>
              Delete
            </Button>
            <Button variant="primary" onClick={onUpdateActivityHandler}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <div>
        <div className="row">
          <div className="chat-and-event-btn ">
            <div className="add-event-btn mb-3">
              <button className="btn-srvc" onClick={onAddEventBtn}>
                Add an activity
              </button>
            </div>
          </div>
        </div>
        {allEvents ? null : (
          <Spinner
            className="spinner-of-coach-activities"
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        <div className="row selected-user-name">
          <h2>
            {clientInfo?.firstName +
              " " +
              clientInfo?.lastName +
              "'s activities"}
          </h2>
          <div className="calendar">
            <Calendar
              localizer={localizer}
              events={allEvents}
              startAccessor="start"
              endAccessor="end"
              style={{ height: "70vh", margin: "0% 2%" }}
              onSelectEvent={handleSelectEvent}
              // views={views}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageActivitiesSelected;
