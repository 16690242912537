import * as actionTypes from "./actionType";
import * as services from "../../services";
import authURL from "../../constants/authURL";
import { toastify } from "../../components/Toast";

// const setAuthHeader = (_token) => {
//   axios.defaults.headers.common['x-access-token'] = `${_token}`;
//   axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//   axios.defaults.headers.common['Content-Type'] = 'application/json';
// };

//   user login
export const userLoginRequest = (values, callback) => (dispatch) => {
  services
    .makePostRequest(authURL.login, values)
    .then((response) => {
      dispatch({ type: actionTypes.USER_LOGIN, payload: response.data });

      if (response) {
        callback(response);
      }
    })
    .catch((error) => {
      callback(error);
    });
};
export const userLogoutRequest = (callback) => (dispatch) => {
  dispatch({ type: actionTypes.USER_LOGOUT, payload: null });
  callback();
};
// send invite people

export const sendInvitePeople =
  (values, callBackForInviteUser, setLoader) => (dispatch) => {
    services
      .makePostRequest(authURL.sendInvitePeopleUrl, values)
      .then((response) => {
        dispatch({ type: actionTypes.SEND_INVITE_PEOPLE, payload: response });

        if (response.status === 200) {
          toastify("success", response.message);
          setLoader(false);
          callBackForInviteUser(response);
        }
      })
      .catch((error) => {
        toastify("error", error.response.data.message);
        setLoader(false);

        // callback(error);
      });
  };
// get all users
export const getAllUsers = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getAllUsersUrl)
    .then((response) => {
      dispatch({ type: actionTypes.GET_ALL_USERS, payload: response });
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};

// get filtered users list

export const getFilteredUsers =
  (organizationId, callback, callbackForError) => (dispatch) => {
    services
      .makePostRequest(authURL.getFilteredUsersUrl, organizationId)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_FILTERED_USERS,
          payload: response.data,
        });

        if (response.status === 200) {
          callback(response);
        }
      })
      .catch((error) => {
        callback(error);
      });
  };
// get survey result of user

export const getSurveyResultOfUser = (userId, callback) => (dispatch) => {
  services
    .makeGetRequest(authURL.getSurveyResultOfUserUrl + `?id=${userId}`)
    .then((response) => {
      dispatch({
        type: actionTypes.SURVEY_RESULT,
        payload: response?.data,
      });

      if (response.status === 200) {
        callback(response);
      }
    })
    .catch((error) => {
      callback(error);
    });
};
// get all coaches
export const getAllCoaches = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getAllCoachesUrl)
    .then((response) => {
      dispatch({ type: actionTypes.GET_ALL_COACHES, payload: response });
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};
// get dashboard data
export const getDashboardData = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getDashboardDataUrl)
    .then((response) => {
      dispatch({ type: actionTypes.GET_DASHBOARD_DATA, payload: response });
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};
// assign coach
export const assignCoach = (data, callback) => (dispatch) => {
  services
    .makePostRequest(authURL.assignCoachUrl, data)
    .then((response) => {
      dispatch({ type: actionTypes.ASSIGN_COACH, payload: response });

      if (response.status === 200) {
        toastify("success", response.message);
        callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response.data.message);
      // callback(error);
    });
};
// block user
export const blockUser = (userId, callback) => (dispatch) => {
  services
    .makePatchRequest(authURL.blockUserUrl + `${userId}`)
    .then((response) => {
      dispatch({ type: actionTypes.BLOCK_USER, payload: response });

      if (response.status === 200) {
        toastify("success", response.message);
        callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response.data.message);
      // callback(error);
    });
};
//unblock user
export const unBlockUser = (userId, callbackForUnBlockUser) => (dispatch) => {
  services
    .makeGetRequest(authURL.unBlockUserUrl + `${userId}`)
    .then((response) => {
      dispatch({ type: actionTypes.UNBLOCK_USER, payload: response });

      if (response.status === 200) {
        toastify("success", response.message);
        callbackForUnBlockUser(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response.data.message);
      // callback(error);
    });
};
// block coach
export const blockCoach = (coachId, callbackForBlockCoach) => (dispatch) => {
  services
    .makePatchRequest(authURL.blockCoachUrl + `${coachId}`)
    .then((response) => {
      dispatch({ type: actionTypes.BLOCK_COACH, payload: response });

      if (response.status === 200) {
        toastify("success", response.message);
        callbackForBlockCoach(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response.data.message);
      // callback(error);
    });
};
//unblock coach
export const unBlockCoach = (coachId, callback) => (dispatch) => {
  services
    .makeGetRequest(authURL.unBlockCoachUrl + `${coachId}`)
    .then((response) => {
      dispatch({ type: actionTypes.UNBLOCK_COACH, payload: response });

      if (response.status === 200) {
        toastify("success", response.message);
        callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response.data.message);
      // callback(error);
    });
};
//block list of users
export const getBlockListOfUsers = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getBlockUsersListUrl)
    .then((response) => {
      dispatch({ type: actionTypes.BLOCK_USERS_LIST, payload: response });

      if (
        response.status === 200 &&
        response.message === "successfully login"
      ) {
        toastify("success", response.message);
        // callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};
// block List of coaches
export const getBlockListOfCoaches = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getBlockCoachesListUrl)
    .then((response) => {
      dispatch({ type: actionTypes.BLOCK_COACHES_LIST, payload: response });

      if (
        response.status === 200 &&
        response.message === "successfully login"
      ) {
        toastify("success", response.message);
        // callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};
// add user
export const addUser = (data, callbackForAddUser) => (dispatch) => {
  services
    .makePostRequest(authURL.addUserUrl, data)
    .then((response) => {
      dispatch({ type: actionTypes.ADD_USER, payload: response });
      if (response.status === 200) {
        toastify("success", response.message);
        callbackForAddUser(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response.data.message);
      // callback(error);
    });
};
// add coach
export const addCoach = (data, callbackForAddCoach) => (dispatch) => {
  services
    .makePostRequest(authURL.addCoachUrl, data)
    .then((response) => {
      dispatch({ type: actionTypes.ADD_COACH, payload: response });

      if (response.status === 200) {
        toastify("success", response.message);
        callbackForAddCoach(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response.data.message);
      // callback(error);
    });
};
// update user
export const updateUser =
  (userId, data, callbackForUpdateUser) => (dispatch) => {
    services
      .makePatchRequest(authURL.updateUserUrl + `${userId}`, data)
      .then((response) => {
        dispatch({ type: actionTypes.UPDATE_USER, payload: response });

        if (response.status === 200) {
          toastify("success", response.message);
          callbackForUpdateUser(response);
        }
      })
      .catch((error) => {
        toastify("error", error?.response?.data?.message);
        // callback(error);
      });
  };
// update coach
export const updateCoach =
  (coachId, data, callBackForUpdateCoach) => (dispatch) => {
    services
      .makePatchRequest(authURL.updateCoachUrl + `${coachId}`, data)
      .then((response) => {
        dispatch({ type: actionTypes.UPDATE_COACH, payload: response });

        if (response.status === 200) {
          toastify("success", response.message);
          callBackForUpdateCoach(response);
        }
      })
      .catch((error) => {
        toastify("error", error.response.data.message);
        // callback(error);
      });
  };
// get selected user activity
export const getselectedUserActivities =
  (userId, callbackForUserActivities) => (dispatch) => {
    services
      .makeGetRequest(authURL.getUserActivitiesUrl + `${userId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ALL_USER_ACTIVITES,
          payload: response,
        });
        callbackForUserActivities(response);
        if (
          response.status === 200 &&
          response.message === "successfully login"
        ) {
          toastify("success", response.message);
        }
      })
      .catch((error) => {
        toastify("error", error.response?.data?.message);
        // callback(error);
      });
  };
//single user activity
export const getSelectedActivityDataOfUser =
  (userId, callBack) => (dispatch) => {
    services
      .makeGetRequest(authURL.selectedActivityDataOfUserUrl + `${userId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_SELECTED_ACTIVITY_DATA_OF_USER,
          payload: response,
        });

        if (response.status === 200) {
          // toastify("success", response.message);
          callBack(response);
        }
      })
      .catch((error) => {
        // toastify("error", error.response?.data?.message);
        // callback(error);
      });
  };

// update activity
export const updateActivity =
  (activityId, activityObj, callback) => (dispatch) => {
    services
      .makePatchRequest(
        authURL.updateActivityUrl + `${activityId}`,
        activityObj
      )
      .then((response) => {
        dispatch({ type: actionTypes.UPDATE_ACTIVITY, payload: response });
        window.location.reload();
        if (response.status === 200) {
          toastify("success", response.message);
          callback(response);
        }
      })
      .catch((error) => {
        toastify("error", error.response.data.message);
        // callback(error);
      });
  };
//get user goals
export const getUserGoals = (userId) => (dispatch) => {
  services
    .makeGetRequest(authURL.getUserGoalsUrl + `${userId}`)
    .then((response) => {
      dispatch({ type: actionTypes.GET_USER_GOALS, payload: response });

      if (
        response.status === 200 &&
        response.message === "successfully login"
      ) {
        toastify("success", response.message);
        // callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};
//delete activity by id
export const deleteActivity = (activityId, callBack) => (dispatch) => {
  services
    .makeDeleteRequest(authURL.deleteActivityByIdUrl + `${activityId}`)
    .then((response) => {
      dispatch({ type: actionTypes.DELETE_ACTIVITY, payload: response });
      window.location.reload();

      if (
        response.status === 200
        // &&
        // response.message === "successfully login"
      ) {
        toastify("success", response.message);
        callBack(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};

//create activtity for user
export const createActivity =
  (userData, setLoaderForCreateActivity, callbackForNewActivity, userId) =>
  (dispatch) => {
    services
      .makePostRequest(authURL.createActivityUrl, userData)
      .then((response) => {
        dispatch({ type: actionTypes.CREATE_ACTIVITY, payload: response });
        if (response.status === 200) {
          toastify("success", response.message);
          setLoaderForCreateActivity(false);
          callbackForNewActivity(response, userId);
        }
      })
      .catch((error) => {
        toastify("error", error.response.data.message);
        setLoaderForCreateActivity(false);
      });
  };
//get matrix for question
export const getMatrixOfQuestion =
  (questionData, callBackForQuestionMatrix) => (dispatch) => {
    services
      .makePostRequest(authURL.getMatrixUrl, questionData)
      .then((response) => {
        dispatch({ type: actionTypes.GET_MATRIX, payload: response });
        if (response.status === 200) {
          // toastify("success", response?.message);
          callBackForQuestionMatrix(response);
        }
      })
      .catch((error) => {
        // toastify("error", error.response?.data?.message);
      });
  };
//add question
export const addQuestion =
  (questionData, callBackOfAddQuestion) => (dispatch) => {
    services
      .makePostRequest(authURL.addQuestionUrl, questionData)
      .then((response) => {
        dispatch({ type: actionTypes.ADD_QUESTION, payload: response });
        if (response.status === 201) {
          toastify("success", response?.message);
          callBackOfAddQuestion(response);
        }
      })
      .catch((error) => {
        toastify("error", error.response?.data?.message);
      });
  };
//get all questions
export const getAllQuestions = () => (dispatch) => {
  services
    .makeGetRequest(authURL.allQuestionsUrl)
    .then((response) => {
      dispatch({ type: actionTypes.ALL_QUESTIONS, payload: response });

      if (
        response.status === 200 &&
        response.message === "successfully login"
      ) {
        toastify("success", response.message);
        // callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};
//get question by id
export const getQuestionById =
  (questionId, callBackForGetQuestion) => (dispatch) => {
    services
      .makeGetRequest(authURL.getQuestionByIdUrl + `${questionId}`)
      .then((response) => {
        dispatch({ type: actionTypes.GET_QUESTION_BY_ID, payload: response });

        if (response.status === 200) {
          // toastify("success", response.message);
          callBackForGetQuestion(response);
        }
      })
      .catch((error) => {
        toastify("error", error.response?.data?.message);
        // callback(error);
      });
  };
//get question position
export const getQuestionPosition =
  (callBackForGetQuestionPosition) => (dispatch) => {
    services
      .makeGetRequest(authURL.getQuestionsPositionsUrl)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_QUESTION_POSITIONS,
          payload: response,
        });

        if (response.status === 200) {
          // toastify("success", response.message);
          callBackForGetQuestionPosition(response);
        }
      })
      .catch((error) => {
        toastify("error", error.response?.data?.message);
        // callback(error);
      });
  };
//delete question by id
export const deleteQuestion = (questionId, callBack) => (dispatch) => {
  services
    .makeDeleteRequest(authURL.deleteQuestionUrl + `${questionId}`)
    .then((response) => {
      dispatch({ type: actionTypes.DELETE_QUESTION, payload: response });

      if (
        response.status === 200
        // &&
        // response.message === "successfully login"
      ) {
        toastify("success", response.message);
        callBack(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};
// update question
export const updateQuestion = (questionId, quesObj, callback) => (dispatch) => {
  services
    .makePatchRequest(authURL.updateQuestionUrl + `${questionId}`, quesObj)
    .then((response) => {
      dispatch({ type: actionTypes.UPDATE_QUESTION, payload: response });

      if (response.status === 200) {
        toastify("success", response.message);
        callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response.data.message);
      // callback(error);
    });
};
//ORGANIZATIONS
//get all organization
export const getAllOrganizations = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getAllOrganizationsUrl)
    .then((response) => {
      dispatch({ type: actionTypes.ALL_ORGANIZATIONS, payload: response });

      if (
        response.status === 200 &&
        response.message === "successfully login"
      ) {
        toastify("success", response.message);
        // callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};
//add Organization
export const addOrganization =
  (data, callbackForAddOrganization) => (dispatch) => {
    const multiPartBody = true;

    services
      .makePostRequest(authURL.addOrganizationUrl, data, multiPartBody)
      .then((response) => {
        dispatch({ type: actionTypes.ADD_ORGANIZATION, payload: response });
        if (response.status === 200) {
          toastify("success", response?.message);
          callbackForAddOrganization(response);
        }
      })
      .catch((error) => {
        toastify("error", error.response?.data?.message);
        callbackForAddOrganization(error.response?.data);
      });
  };
// block Organization
export const blockOrganization = (organizationId, callback) => (dispatch) => {
  services
    .makePatchRequest(authURL.blockOrganizationUrl + `${organizationId}`)
    .then((response) => {
      dispatch({ type: actionTypes.BLOCK_ORGANIZATION, payload: response });

      if (response.status === 200) {
        toastify("success", response.message);
        callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response.data.message);
      // callback(error);
    });
};
//unblock Organization
export const unBlockOrganization =
  (organizationId, callbackForUnBlockOrganization) => (dispatch) => {
    services
      .makeGetRequest(authURL.unBlockOrganizationUrl + `${organizationId}`)
      .then((response) => {
        dispatch({ type: actionTypes.UNBLOCK_ORGANIZATION, payload: response });

        if (response.status === 200) {
          toastify("success", response.message);
          callbackForUnBlockOrganization(response);
        }
      })
      .catch((error) => {
        toastify("error", error.response.data.message);
        // callback(error);
      });
  };
// block List of Organizations
export const getBlockListOfOrganizations = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getBlockOrganizationsListUrl)
    .then((response) => {
      dispatch({
        type: actionTypes.BLOCK_ORGANIZATIONS_LIST,
        payload: response,
      });

      if (
        response.status === 200 &&
        response.message === "successfully login"
      ) {
        toastify("success", response.message);
        // callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};
// update Organization
export const updateOrganization =
  (organizationId, data, callback) => (dispatch) => {
    const multiPartBody = true;

    services
      .makePostRequest(
        authURL.updateOrganizationUrl + `${organizationId}`,
        data,
        multiPartBody
      )
      .then((response) => {
        dispatch({ type: actionTypes.UPDATE_ORGANIZATION, payload: response });

        if (response.status === 200) {
          toastify("success", response.message);
          callback(response);
        }
      })
      .catch((error) => {
        toastify("error", error.response.data.message);
        // callback(error);
      });
  };
//upload file
export const onUploadFile = (data, callbackForMP4Upload) => (dispatch) => {
  var multiPartBody = true;

  services
    .makePostRequest(authURL.uploadFileUrl, data, multiPartBody)
    .then((response) => {
      dispatch({ type: actionTypes.UPLOAD_FILE, payload: response });
      if (response) {
        callbackForMP4Upload(response);
      }
      multiPartBody = false;
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
    });
};

//upload content
export const uploadContentData = (data, callBack) => (dispatch) => {
  const multiPartBody = false;
  services
    .makePostRequest(authURL.uploadContentUrl, data, multiPartBody)
    .then((response) => {
      dispatch({ type: actionTypes.UPLOAD_CONTENT, payload: response });
      if (response.status === 201) {
        toastify("success", response?.message);
        callBack(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
    });
};
//update content
// block Organization
export const updateContent = (editContentObj, callback) => (dispatch) => {
  const multiPartBody = false;

  services
    .makePatchRequest(
      authURL.updateContentUrl + `${editContentObj?.contentId}`,
      editContentObj
    )
    .then((response) => {
      dispatch({ type: actionTypes.UPDATE_CONTENT, payload: response });

      if (response.status === 200) {
        toastify("success", response.message);
        callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response.data.message);
      // callback(error);
    });
};
//get content list by plan
export const contentListByPlan =
  (goalIds, setLoadingForContent) => (dispatch) => {
    services
      .makePostRequest(authURL.contentListByPlanUrl, { goalIds })
      .then((response) => {
        dispatch({ type: actionTypes.CONTENTLIST_BY_PLAN, payload: response });

        if (response.status === 200) {
          setLoadingForContent(false);
          // callback(response);
        }
      })
      .catch((error) => {
        toastify("error", error.response?.data?.message);
        // callback(error);
      });
  };
// delete content
export const deleteContent = (contentId, callback) => (dispatch) => {
  services
    .makeDeleteRequest(authURL.deleteContentUrl + `${contentId}`)
    .then((response) => {
      dispatch({ type: actionTypes.DELETE_CONTENT, payload: response });

      if (response.status === 200) {
        toastify("success", response?.message);
        callback(response, contentId);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};
//daily content

export const getDailyContent = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getDailyContentUrl)
    .then((response) => {
      dispatch({ type: actionTypes.DAILY_CONTENT, payload: response });

      if (response.status === 200) {
        // toastify("success", response.message);
        // callback(response);
      }
    })
    .catch((error) => {
      // toastify("error", error.response.data.message);
      // callback(error);
    });
};
//get content

export const getContent = (contentId, callBack) => (dispatch) => {
  services
    .makeGetRequest(authURL.getContentUrl + `${contentId}`)
    .then((response) => {
      dispatch({ type: actionTypes.GET_CONTENT, payload: response });

      if (response.status === 200) {
        // toastify("success", response.message);
        callBack(response);
      }
    })
    .catch((error) => {
      // toastify("error", error.response.data.message);
      // callback(error);
    });
};
//all goals

export const getAllGoals = () => (dispatch) => {
  services
    .makeGetRequest(authURL.allGoalsUrl)
    .then((response) => {
      dispatch({ type: actionTypes.ALL_GOALS, payload: response });

      if (response.status === 200) {
        // toastify("success", response.message);
        // callback(response);
      }
    })
    .catch((error) => {
      // toastify("error", error.response.data.message);
      // callback(error);
    });
};
//plans
//get all Plans
export const getAllPlans = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getAllPlansUrl)
    .then((response) => {
      dispatch({ type: actionTypes.ALL_PLANS, payload: response });

      if (
        response.status === 200 &&
        response.message === "successfully login"
      ) {
        // toastify("success", response.message);
        // callback(response);
      } else {
        // toastify("warning", response.message);
        // callBack(response.message);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};

export const getFilteredPlans = (data, callBack) => (dispatch) => {
  services
    .makePostRequest(authURL.getFilteredPlansUrl, data)
    .then((response) => {
      dispatch({ type: actionTypes.FILTERED_PLANS, payload: response });
      // if (response.status === 201) {
      //   toastify("success", response?.message);
      //   callBack(response);
      // }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
    });
};

//get all free Plans
export const getAllFreePlans = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getAllFreePlansUrl)
    .then((response) => {
      dispatch({ type: actionTypes.ALL_FREE_PLANS, payload: response });

      if (
        response.status === 200
        //  &&
        // response.message === "successfully login"
      ) {
        // toastify("success", response.message);
        // callback(response);
      } else {
        // toastify("warning", response.message);
        // callBack(response.message);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};
//add Plan
export const addPlan = (data, callbackForAddPlan) => (dispatch) => {
  services
    .makePostRequest(authURL.addPlanUrl, data)
    .then((response) => {
      dispatch({ type: actionTypes.ADD_PLAN, payload: response });
      if (response.status === 200) {
        toastify("success", response?.message);
        callbackForAddPlan(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
    });
};
// update plan
export const updateplan = (planId, data, callback) => (dispatch) => {
  services
    .makePatchRequest(authURL.updatePlanUrl + `${planId}`, data)
    .then((response) => {
      dispatch({ type: actionTypes.UPDATE_PLAN, payload: response });

      if (response.status === 200) {
        toastify("success", response.message);
        callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response.data.message);
      // callback(error);
    });
};
// delete plan
export const deletePlan = (planId, callback) => (dispatch) => {
  services
    .makePatchRequest(authURL.deletePlanUrl + `${planId}`)
    .then((response) => {
      dispatch({ type: actionTypes.DELETE_PLAN, payload: response });

      if (response.status === 200) {
        toastify("success", response?.message);
        callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
      // callback(error);
    });
};
//PLAN DAY LIST

export const planDayList = (planId) => (dispatch) => {
  services
    .makeGetRequest(authURL.planDayListUrl + `${planId}`)
    .then((response) => {
      dispatch({ type: actionTypes.PLAN_DAY_LIST, payload: response });

      if (response.status === 200) {
        // toastify("success", response.message);
        // callback(response);
      }
    })
    .catch((error) => {
      // toastify("error", error.response.data.message);
      // callback(error);
    });
};
//upload CSV
export const uploadCSV = (data, callback) => (dispatch) => {
  services
    .makePostRequest(authURL.uploadCsvUrl, data, true)
    .then((response) => {
      dispatch({ type: actionTypes.UPLOAD_CSV, payload: response });
      if (response.status === 200) {
        toastify("success", response?.message);
        callback(response);
      }
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
    });
};

//report

export const getOrganizationReport =
  (organizationId, callBack) => (dispatch) => {
    services
      .makeGetRequest(authURL.getOrganizationReportUrl + `${organizationId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ORGANIZATION_REPORT,
          payload: response,
        });

        if (response.status === 200) {
          callBack(response);
        }
      })
      .catch((error) => {
        // callback(error);
      });
  };
export const getCoachReport = (callBack) => (dispatch) => {
  services
    .makeGetRequest(authURL.getCoachReportUrl)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_COACH_REPORT,
        payload: response,
      });

      if (response.status === 200) {
        callBack(response);
      }
    })
    .catch((error) => {
      // callback(error);
    });
};
export const getFeedbacks = (callBack) => (dispatch) => {
  services
    .makeGetRequest(authURL.getFeedbacksUrl)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_FEEDBACKS,
        payload: response,
      });

      if (response.status === 200) {
        callBack(response);
      }
    })
    .catch((error) => {
      // callback(error);
    });
};
