import React from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
// import AppHeader from "./AppHeader";
const Layout = ({ component }) => {
  const ComponentToRender = component;
  return (
    <>
      <div className="dashboard-main wrap">
        <Navbar />
        <div className="row">
          <div className="col-md-auto p-0">
            <Sidebar />
          </div>
          <div className="col-md p-0">
            {/* <AppHeader /> */}

            <ComponentToRender />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
